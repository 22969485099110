import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/NoteLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "wcag20-guideline-14-distinguishable",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#wcag20-guideline-14-distinguishable",
        "aria-label": "wcag20 guideline 14 distinguishable permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`WCAG20 Guideline 1.4 Distinguishable`}</h1>
    <blockquote>
      <p parentName="blockquote">{`Guideline 1.4 Distinguishable: Make it easier for users to see and hear
content including separating foreground from background.`}</p>
      <p parentName="blockquote"><em parentName="p">{`Source: `}<a parentName="em" {...{
            "href": "https://www.w3.org/TR/WCAG20/#visual-audio-contrast"
          }}>{`https://www.w3.org/TR/WCAG20/#visual-audio-contrast`}</a></em></p>
    </blockquote>
    <h2 {...{
      "id": "guidelines-on-color-and-contrast",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#guidelines-on-color-and-contrast",
        "aria-label": "guidelines on color and contrast permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Guidelines on color and contrast`}</h2>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`1.4.1 Use of Color`}</strong></p>
      <p parentName="blockquote">{`Color is not used as the only visual means of conveying information,
indicating an action, prompting a response, or distinguishing a visual
element. (Level A)`}</p>
      <p parentName="blockquote"><em parentName="p">{`Note:`}</em>{` This success criterion addresses color perception specifically.
Other forms of perception are covered in
`}<a parentName="p" {...{
          "href": "https://www.w3.org/TR/WCAG20/#content-structure-separation"
        }}>{`Guideline 1.3`}</a>{`
including programmatic access to color and other visual presentation
coding.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`1.4.3 Contrast (Minimum)`}</strong></p>
      <p parentName="blockquote">{`The visual presentation of text and images of text has a contrast ratio of
at least 4.5:1, except for the following: (Level AA)`}</p>
      <ul parentName="blockquote">
        <li parentName="ul">
          <p parentName="li">{`Large Text: Large-scale text and images of large-scale text have a
contrast ratio of at least 3:1;`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Incidental: Text or images of text that are part of an inactive user
interface component, that are pure decoration, that are not visible to
anyone, or that are part of a picture that contains significant other
visual content, have no contrast requirement.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Logotypes: Text that is part of a logo or brand name has no minimum
contrast requirement.`}</p>
        </li>
      </ul>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`1.4.6 Contrast (Enhanced)`}</strong></p>
      <p parentName="blockquote">{`The visual presentation of text and images of text has a contrast ratio of
at least 7:1, except for the following: (Level AAA)`}</p>
      <ul parentName="blockquote">
        <li parentName="ul">
          <p parentName="li">{`Large Text: Large-scale text and images of large-scale text have a
contrast ratio of at least 4.5:1;`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Incidental: Text or images of text that are part of an inactive user
interface component, that are pure decoration, that are not visible to
anyone, or that are part of a picture that contains significant other
visual content, have no contrast requirement.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Logotypes: Text that is part of a logo or brand name has no minimum
contrast requirement.`}</p>
        </li>
      </ul>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`1.4.8 Visual Presentation`}</strong></p>
      <p parentName="blockquote">{`Visual Presentation: For the visual presentation of blocks of text, a
mechanism is available to achieve the following: (Level AAA)`}</p>
      <ol parentName="blockquote">
        <li parentName="ol">{`Foreground and background colors can be selected by the user.`}</li>
        <li parentName="ol">{`Width is no more than 80 characters or glyphs (40 if CJK).`}</li>
        <li parentName="ol">{`Text is not justified (aligned to both the left and the right margins).`}</li>
        <li parentName="ol">{`Line spacing (leading) is at least space-and-a-half within paragraphs,
and paragraph spacing is at least 1.5 times larger than the line
spacing`}</li>
        <li parentName="ol">{`Text can be resized without assistive technology up to 200 percent in a
way that does not require the user to scroll horizontally to read a
line of text on a full-screen window.`}</li>
      </ol>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      